import { Session } from 'smg/admin/session';

export async function DefaultQuery() {
    let defaultQuery = 'Active:yes';

    const sess = await Session();
    if (sess.user.salesTeamUsers?.length) {
        defaultQuery += ` Team:None,${sess.user.salesTeamUsers.map(salesTeamUser => `"${salesTeamUser.salesTeam?.name ?? ''}"`).join(',')}`;
    }

    return defaultQuery;
}

export async function FridayQuery() {
    return `${await DefaultQuery()} -Status:"Customer Canceled" -Status:Sent`;
}

export async function ArtDoneQuery() {
    return `${await DefaultQuery()} Status:"Art Done"`;
}

export async function ReviewNeededQuery() {
    return `${await DefaultQuery()} Status:"Review Needed"`;
}