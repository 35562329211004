import { Session } from 'smg/admin/session';

export async function DefaultQuery(): Promise<string> {
    let defaultQuery = 'active:yes has:quotesCount';

    const sess = await Session();

    if (sess.user.salesTeamUsers?.length) {
        defaultQuery += ` Team:None,${sess.user.salesTeamUsers.map(salesTeamUser => `"${salesTeamUser.salesTeam?.name ?? ''}"`).join(',')}`;
    }

    return defaultQuery;
}