import { SetBaseURL } from 'smg/axios';
import Batch from 'smg/batch';
import * as sidebar from '../sidebar';

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
const _self: SharedWorkerGlobalScope = self as any;

const connections = new Set<connection>();

class connection {
    Alive: Date = new Date();
    Active: boolean = true;

    constructor(public Port: MessagePort) {
        connections.add(this);
    }

    BumpAlive() {
        this.Alive = new Date();
    }

    Expired(): boolean {
        return (Number(new Date()) - Number(this.Alive)) > 1 * 60 * 1000;
    }

    Destroy() {
        connections.delete(this);
    }

    Send() {
        this.Port.postMessage(currentMap);
    }

    SetActive(active: boolean = true) {
        this.Active = active;
    }
}

const currentMap: sidebar.WorkerResponse = new Map();

sidebar.AllItems.forEach(item => {
    item.CurrentMap = currentMap;
});

const registeredSidebarItems = new Set<sidebar.Item>();

_self.onconnect = e => {
    const conn = new connection(e.ports[0]);

    conn.Port.onmessage = e => {
        conn.BumpAlive();

        const data = e.data as sidebar.WorkerRequest;

        switch (data.action) {
        case sidebar.WorkerAction.SetAxiosDefaults:
            SetBaseURL(data.axiosBaseURL ?? '');
            break;
        case sidebar.WorkerAction.RegisterSidebarItem: {
            const item = sidebar.AllItems.get(data.href ?? '');
            if (item) {
                registeredSidebarItems.add(item);
            }
            break;
        }
        case sidebar.WorkerAction.Get: {
            let anyUnloaded = false;
            for (const item of registeredSidebarItems) {
                if (!item.Loaded) {
                    anyUnloaded = true;
                    break;
                }
            }

            if (anyUnloaded) {
                update();
                break;
            }

            conn.Send();
            break;
        }
        case sidebar.WorkerAction.Update:
            update();
            break;
        case sidebar.WorkerAction.Leave:
            conn.Destroy();
            break;
        case sidebar.WorkerAction.Active:
            conn.SetActive();
            break;
        case sidebar.WorkerAction.Inactive:
            conn.SetActive(false);
            break;
        default:
        // do nothing
        }
    };
};

// connection garbage collector
setInterval(() => {
    connections.forEach(conn => {
        if (conn.Expired()) {
            conn.Destroy();
        }
    });
}, 1000);

async function get() {
    if (!registeredSidebarItems.size) {
        return;
    }

    const b = new Batch();

    for (const item of registeredSidebarItems) {
        const update = await item.Updater(b);
        void update();
    }

    await b.Exec();
}

function sendAll() {
    connections.forEach(conn => conn.Send());
}

let updater: Promise<unknown> | undefined;
function update() {
    if (updater) {
        return;
    }

    updater = get().
        then(() => sendAll()).
        finally(() => {
            let timeout = 10 * 1000;
            if (allConnectionsInactive()) {
                timeout = 10 * 60 * 1000;
            }

            setTimeout(() => update(), timeout);
            updater = undefined;
        });
}

function allConnectionsInactive(): boolean {
    for (const conn of connections) {
        if (conn.Active) {
            return false;
        }
    }
    return true;
}
