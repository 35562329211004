import { HasRole, Session } from 'smg/admin/session';
import * as models from 'smg/models';

export async function DefaultQuery() {
    let defaultQuery = '';

    const sess = await Session();
    if (sess.user.salesTeamUsers?.length) {
        defaultQuery = `Team:None,${sess.user.salesTeamUsers.map(salesTeamUser => `"${salesTeamUser.salesTeam?.name ?? ''}"`).join(',')}`;
    }

    if (sess.user.userFactories?.length) {
        defaultQuery = `Factory:${sess.user.userFactories.map(userFactory => `"${userFactory.factory?.name ?? ''}"`).join(',')}`;
    }

    return defaultQuery;
}

export async function InboxQuery() {
    return `${await DefaultQuery()} ${await HasRole(models.AdminRoleID.ADMIN_ROLES_FACTORY) || await HasRole(models.AdminRoleID.ADMIN_ROLES_SALES) ?
        'bidsNeededNoFactory:no' : ''} active:yes to:me addressed:no type:sales,factory,frontOffice has:createdByUserID -createdByUserID:me toAllFactories:no`;
}

export async function NeedsApprovalQuery() {
    return `${await DefaultQuery()} active:yes approved:no to:factory`;
}